$CONTROL_PROGRESS_HEIGHT: 2px;

@mixin animationInfinite($ANIMATIONNAME, $DURATION) {
  -webkit-animation: $ANIMATIONNAME $DURATION linear infinite;
  animation: $ANIMATIONNAME $DURATION linear infinite;
}

.control-progress-container {
  -webkit-transition: "max-height" .4s;
  -moz-transition: "max-height" .4s;
  transition: "max-height" .4s;
  background: rgba(207,244,249,0.25);
  box-shadow: 0 0 5px 0 rgba(207,244,249,0.25);
  margin: 0;
  max-height: 0;
  opacity: .8;
  overflow: hidden;
  text-align: center;
  width: 100%;
  z-index: 1000;

  &.page-wide {
    position: absolute;
    top: 0;
  }

  &.revealed {
    max-height: $CONTROL_PROGRESS_HEIGHT;

    .control-progress-bar {
      @include animationInfinite('indeterminate-progress-bar', 2s);
    }
  }

  .control-progress-bar {
    background: #2196f3;
    height: 2px;
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}

@-webkit-keyframes indeterminate-progress-bar {
  0% {
    -webkit-transform: scaleX(1) translateX(-100%);
  }

  50% {
    -webkit-transform: scaleX(1) translateX(0%);
  }

  75% {
    -webkit-transform: scaleX(1) translateX(0%);
    -webkit-animation-timing-function: cubic-bezier(.28,.62,.37,.91);
  }

  100% {
    -webkit-transform: scaleX(0) translateX(0%);
  }
}

@keyframes indeterminate-progress-bar {
  0% {
    transform: scaleX(1) translateX(-100%);
  }

  50% {
    transform: scaleX(1) translateX(0%);
  }

  75% {
    transform: scaleX(1) translateX(0%);
    animation-timing-function: cubic-bezier(.28,.62,.37,.91);
  }

  100% {
    transform: scaleX(0) translateX(0%);
  }
}
