dl.timestamp-summary {
  dd {
    margin-bottom: 1rem;
  }
}

.hash-part {
  display: inline-block;
  margin-right: .5rem;
}

table.operations {
  pre {
    margin-bottom: .5rem;
  }
}

#recents li a {
  font-family: monospace;
}

.description {
  display: block;
  font-size: 80%;
  font-weight: 400;
  padding-bottom: .5rem;
}
