body {
  font-size: .9rem;
}

a,
.btn {
  -webkit-transition: none;
  transition: none;
}

.my-7 {
  margin-top: 5rem!important;
  margin-bottom: 5rem!important;
}
