body.location {
  padding-bottom: 5rem;

  .inline-dl {
    dt {
      float: left;
    }

    dd {
      text-align: right;
    }
  }

  #btn-location {
    background-image: url(/static/images/location.svg);
    background-position: 4px center;
    background-repeat: no-repeat;
    background-size: 32px;
    padding-left: 40px;
  }

  .raw-geojson {
    background-color: #EEE;
  }

  .copy-geojson {
    border-width: 1px;
    float: right;
  }
}
