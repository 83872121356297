.logo-text {
  color: #444;
  line-height: 1;

  &:hover {
    color: #444;
    text-decoration: none;
  }

  .line {
    display: block;
  }

  .dropcap {
    font-size: 120%;
    font-weight: bold;
  }
}

.jumbotron {
  background-color: #F1F1F1;
}

.idl-logo {
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 2rem;
  margin-bottom: 2rem;
  padding-left: 3rem;

  &.idl-logo-apache {
    background-image: url(/static/logos/apache.svg);
  }

  &.idl-logo-google {
    background-image: url(/static/logos/google.png);
  }

  &.idl-logo-json {
    background-image: url(/static/logos/json-schema.svg);
  }

  &.idl-logo-w3c {
    background-image: url(/static/logos/w3c.svg);
  }

  &.idl-logo-flatbuffers {
    background-image: url(/static/logos/flatbuffers.svg);
  }
}

figcaption {
  font-size: 0.6rem;
  font-style: italic;
  padding: 0.5rem;
}
